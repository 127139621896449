import { mount } from "@vue/test-utils";
import LanguageDropDown from "@/components/WrapperComponents/LanguageDropDown.vue";

describe("LanguageDropDown.vue", () => {
  let wrapper;
  beforeEach(() => {
    wrapper = mount(LanguageDropDown, {
      propsData: {
        text: "Language",
        defaultValue: "EN",
        options: [
          {
            value: "AR",
            text: "Arabic"
          },
          {
            value: "ZH",
            text: "Chinese"
          },
          {
            value: "EN",
            text: "English"
          },
          {
            value: "FR",
            text: "French"
          },
          {
            value: "ES",
            text: "Spanish"
          },
          {
            value: "RU",
            text: "Russian"
          }
        ],
        variant: "default"
      }
    });
  });

  it("render when created ", () => {
    expect(wrapper.exists()).toBeTruthy();
    expect(wrapper.element).toMatchSnapshot();
  });

  it("renders props correctly when passed", () => {
    expect(wrapper.text()).toContain("Language");
    expect(wrapper.findAllComponents("li")).toHaveLength(6);
  });

  it("should emit the default value when passed", () => {
    // assert event has been emitted
    expect(wrapper.emitted().onSelect).toBeTruthy();
    // assert event count
    expect(wrapper.emitted().onSelect.length).toBe(1);
    // assert event payload
    expect(wrapper.emitted().onSelect[0][0].value).toEqual("EN");
  });

  it("should update the selected value when default value passed", () => {
    expect(wrapper.vm.selected.value).toEqual("EN");
  });

  it("should update the selected value and emit the value when LanguageDropDown item clicked", async () => {
    await wrapper.find("a").trigger("click");
    expect(wrapper.vm.selected.value).toEqual("AR");
    // assert event payload
    expect(wrapper.emitted().onSelect[1][0].value).toEqual("AR");
  });

  it("should update prop variant and render UI based on the props", async () => {
    await wrapper.setProps({ variant: "menu" });
    expect(wrapper.find(".lxp-dropdown__menu-text").isVisible()).toBe(true);
  });
});
